import React from 'react';
import { teal }
    from "../components/base/colors"


const LinkedInSVG = () => (
    <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M34.9339 34.9348H28.859V25.4209C28.859 23.1522 28.8185 20.2317 25.6995 20.2317C22.5354 20.2317 22.0513 22.7035 22.0513 25.2557V34.9341H15.9764V15.3697H21.8083V18.0434H21.89C22.4736 17.0454 23.317 16.2245 24.3303 15.668C25.3435 15.1114 26.4888 14.8402 27.6441 14.8831C33.8012 14.8831 34.9365 18.9331 34.9365 24.202L34.9339 34.9348ZM9.12186 12.6954C8.42461 12.6955 7.74297 12.4889 7.16316 12.1016C6.58335 11.7143 6.13143 11.1638 5.86449 10.5196C5.59755 9.87548 5.52759 9.16664 5.66349 8.48274C5.7994 7.79884 6.13505 7.1706 6.62799 6.67747C7.12093 6.18433 7.74903 5.84845 8.43286 5.7123C9.11669 5.57614 9.82552 5.64583 10.4697 5.91255C11.114 6.17927 11.6646 6.63104 12.0521 7.21073C12.4396 7.79042 12.6465 8.472 12.6466 9.16927C12.6467 9.63224 12.5556 10.0907 12.3785 10.5185C12.2014 10.9462 11.9418 11.3349 11.6145 11.6623C11.2872 11.9898 10.8986 12.2495 10.4709 12.4268C10.0432 12.604 9.58482 12.6953 9.12186 12.6954ZM12.1593 34.9348H6.07807V15.3697H12.1593V34.9348ZM37.9625 0.0027934H3.02544C2.23247 -0.00615561 1.46834 0.300092 0.901043 0.854239C0.333744 1.40839 0.00967781 2.1651 0 2.95809V38.0413C0.00934658 38.8347 0.333222 39.5919 0.900503 40.1466C1.46778 40.7014 2.23207 41.0082 3.02544 40.9998H37.9625C38.7575 41.0097 39.5239 40.7038 40.0934 40.1491C40.663 39.5943 40.989 38.8363 41 38.0413V2.95556C40.9887 2.16099 40.6624 1.40342 40.0929 0.849288C39.5233 0.295154 38.7571 -0.0102183 37.9625 0.000261071" fill={teal} />
    </svg>

)

export default LinkedInSVG