import React from 'react';
import { teal }
    from "../components/base/colors"

const PaperIconSVG = (props) => (
    <svg width="40" height="40" viewBox="0 0 175 125" fill="none" xmlns="http://www.w3.org/2000/svg">
       <path d="M98.63,35.57A8.37,8.37,0,0,0,96,32.12L64.39,1.32A3.53,3.53,0,0,0,61.63,0H6.43A6.42,6.42,0,0,0,0,6.43v110a6.42,6.42,0,0,0,6.43,6.43H92.21a6.42,6.42,0,0,0,6.4-6.43V63.37h0V35.57Zm-33.43-23L86.68,32.69H65.2V12.57ZM49.75,115.7H7.18V7.15H58V36.26a3.61,3.61,0,0,0,3.61,3.61H91.45v23.5h0V115.7ZM20.47,53a2.58,2.58,0,0,1,1.87-.88H64.53a2.55,2.55,0,0,1,1.91.87,3,3,0,0,1,.76,2,3.08,3.08,0,0,1-.77,2,2.57,2.57,0,0,1-1.9.88H22.37a2.56,2.56,0,0,1-1.91-.87,3.1,3.1,0,0,1,0-4.08Zm0,40.77a2.53,2.53,0,0,1,1.9-.88H76.25a2.55,2.55,0,0,1,1.91.88,3.1,3.1,0,0,1,0,4.08,2.59,2.59,0,0,1-1.92.86H22.37a2.59,2.59,0,0,1-1.91-.86,3.1,3.1,0,0,1,0-4.08ZM76.25,72.52a2.59,2.59,0,0,1,1.91.88,3.1,3.1,0,0,1,0,4.08,2.57,2.57,0,0,1-1.92.87H22.37a2.56,2.56,0,0,1-1.91-.87,3.1,3.1,0,0,1,0-4.08,2.57,2.57,0,0,1,1.9-.88ZM20.47,32.63a2.57,2.57,0,0,1,1.9-.88H41.86a2.56,2.56,0,0,1,1.91.87,3,3,0,0,1,.76,2,3.07,3.07,0,0,1-.75,2l0,0a2.58,2.58,0,0,1-1.9.88H22.37a2.56,2.56,0,0,1-1.91-.87,3.1,3.1,0,0,1,0-4.08Z" stroke={props.color? props.color : teal} stroke-width="2.5"/>
    </svg>
);

export default PaperIconSVG